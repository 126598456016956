import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import R11nHomePage from "./R11nHomePage";

const App = () => (
    <Router>
        <div>
            <nav className="navbar navbar-inverse navbar-fixed-top bg-dark" role="navigation">
                <div className="container">
                    <div className="navbar-header">
                        <Link to="/" className="navbar-brand" >r11n.com</Link>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">

                    </div>
                </div>
            </nav>

            <Route exact path="/" component={R11nHomePage} />
        </div>
    </Router>
);


export default App;