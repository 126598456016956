import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class R11nHomePage extends Component {
    render() {

        return (
<div className="coming-soon" >

    <div className="projects-container">
        <h3>Coming soon</h3>
        <ul>
            <li><b>Startup Nebula</b></li>
            <li><b>True value real-estate</b></li>
            <li><b>Robo trader</b></li>
        </ul>
    </div>

</div>
        )

        // {
        //     false && (
        //         <div className="App">
        //             <header className="App-header">
        //                 <img src={logo} className="App-logo" alt="logo"/>
        //                 <p>
        //                     Edit <code>src/App.js</code> and save to reload.
        //                 </p>
        //                 <a
        //                     className="App-link"
        //                     href="https://reactjs.org"
        //                     target="_blank"
        //                     rel="noopener noreferrer"
        //                 >
        //                     Learn React
        //                 </a>
        //             </header>
        //         </div>
        //     );
        // }
    }
}

export default R11nHomePage;
